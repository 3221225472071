<template>
  <section class="tos">
    <div class="tos__content">
      <h3 style="font-weight: 800">
        Terms of Use
      </h3>
      <p>
        This website (this “Site”), located at {{ $store.state.brand.name }}, is
        owned and operated by {{ $store.state.brand.name }} (together with its
        affiliates, individually or collectively, “{{
          $store.state.brand.name
        }}”). The following terms and conditions (the “Terms of Use”) apply to
        your use of this Site. BY USING THIS SITE, YOU ACKNOWLEDGE THAT YOU HAVE
        READ, UNDERSTOOD AND AGREE TO THESE TERMS OF USE; IF YOU DO NOT AGREE,
        PLEASE DO NOT USE THIS SITE. Your failure to comply with these terms of
        use will result in the automatic termination, without further notice, of
        any rights granted to you regarding your use of this Site or any product
        or service offered on or through this Site.
      </p>
      <p>
        Its sole discretion, revise these Terms of Use and any other information
        contained in this Site at any time; we will specify on this Site the
        date on which these Terms of Use were last revised. It is your
        responsibility to check these Terms of Use periodically for changes.
        Your continued use of this Site following the posting of changes
        constitutes your acceptance of and agreement to them.
        {{ $store.state.brand.name }} may also, at its sole discretion, at any
        time make improvements or changes in the products, services or programs
        described on this Site.
      </p>
      <h5>
        {{ $store.state.brand.name }} Is a Product Advertising and Research
        Service
      </h5>
      <p>
        {{ $store.state.brand.name }} operates this Site as an online
        advertising and research service for car buyers, sellers and financing
        sources. {{ $store.state.brand.name }} does not sell vehicles or provide
        financing directly and is never a party to any transaction between
        buyers and sellers or between buyers and financing sources. As a result,
        {{ $store.state.brand.name }} does not (a) guarantee or otherwise ensure
        any vehicle or any transaction between a buyer and a seller or a buyer
        and a financing source, (b) collect or process payment or transfer of
        title on behalf of buyers or sellers, or (c) warehouse, store, ship or
        deliver any vehicles.
      </p>
      <p>
        Advertisers on this Site may include information about special offers,
        incentives or pricing associated with a specific brand, model or vehicle
        (“Offers”). {{ $store.state.brand.name }} is not responsible for the
        content of any Offers, nor responsible for any errors or omissions in
        Offer content. Users of this Site should contact the relevant advertiser
        for full details on any Offers, including eligibility requirements,
        limitations and restrictions, and availability.
      </p>
      <h5>
        Content
      </h5>
      <p>
        All text, graphics, interfaces, photographs, trademarks, logos and
        computer code contained on this Site (collectively, “Content”),
        including but not limited to the design, structure, selection,
        coordination, expression, look and feel, and arrangement of such Content
        are owned, controlled or licensed by or to
        {{ $store.state.brand.name }}, and are protected by trade dress,
        copyright, trademark laws, and other intellectual property rights and
        laws. Except as expressly provided in these Terms of Use or in any
        additional terms and conditions to which you become subject (as
        described in the section titled “Purchases on this Site; Access to
        Certain Portions of this Site”), no part of this Site and the Content
        may be copied, reproduced, republished, uploaded, posted, transmitted,
        translated or distributed in any way to or via any medium, equipment,
        device or website for publication or distribution or other commercial
        use, without {{ $store.state.brand.name }}'s prior written consent.
      </p>
      <h5>
        Your Use of this Site
      </h5>
      <p>
        {{ $store.state.brand.name }} grants you a personal, non-exclusive,
        non-transferable and limited privilege to use this Site provided that
        you comply with these Terms of Use.
      </p>
      <p>
        You may not:
      </p>
      <p>
        <b>i.</b> Decompile, reverse engineer, disassemble or unlawfully use or
        reduce any of the software, copyrighted or trademarked material, trade
        secrets, or other proprietary information contained in this Site;
        <br /><b>ii.</b> Dttempt to gain unauthorized access to any portion or
        feature of this Site or any other systems or networks connected to this
        Site, or to any product or service offered on or through this Site, by
        any means; <br /><b>iii.</b> Probe, scan or test the vulnerability of,
        or breach the security or authentication measures on, this Site or any
        other systems or networks connected to this Site, or otherwise attempt
        to interfere with the proper functioning of this Site or such other
        systems or networks; <br /><b>iv.</b> Reverse look-up, trace or seek to
        trace any information on any other user of or visitor to this Site, or
        any other customer of {{ $store.state.brand.name }}, including any
        {{ $store.state.brand.name }} account not owned by you, to its source,
        or exploit this Site, or any product or service made available or
        offered by or through this Site, in any way with the purpose to access,
        acquire or reveal any information, including but not limited to personal
        identity or information, other than your own information, as provided
        for by this Site; <br /><b>v.</b> Forge headers or otherwise manipulate
        identifiers in order to disguise the origin of any message or
        transmittal you send to {{ $store.state.brand.name }} on or through this
        Site or any product or service offered on or through this Site, or
        pretend that you are or that you represent someone else, or impersonate
        any individual or entity when using this Site; <br /><b>vi.</b> Take any
        action that imposes an unreasonable or disproportionately large load on
        the infrastructure of this Site or {{ $store.state.brand.name }}’s
        systems or networks, or any systems or networks connected to this Site;
        <br /><b>vii.</b> Use any device, software, or routine to interfere or
        attempt to interfere with the proper working of this Site or any
        transaction being conducted on this Site, or with any other person’s use
        of this Site; <br /><b>viii.</b>
        Use this Site to defame, harass, threaten or intimidate others; or
        <br /><b>ix.</b> Use this Site or any Content for any unlawful or
        prohibited purpose, or solicit the performance of any illegal activity.
      </p>
      <p>
        Except for the limited permission in the preceding paragraph,
        {{ $store.state.brand.name }}
        does not grant you any express or implied rights or licenses under any
        patents, trademarks, copyrights or other proprietary or intellectual
        property rights.
      </p>
      <p>
        If any materials are available for downloading, access or other use from
        this Site with their own license terms, conditions and notices, such
        license terms, conditions and notices will govern.
      </p>
      <h5>
        Purchases on this Site; Access to Certain Portions of Site
      </h5>
      <p>
        Additional terms and conditions may apply to purchases of products or
        services made available or otherwise featured on or through this Site
        and/or to your access to (and use of) certain portions of this Site, all
        of which terms are made a part of these Terms of Use by this reference.
        If there is a conflict between these Terms of Use and the terms posted
        for or otherwise applicable to a specific portion of this Site or for
        any product or service offered on or through this Site, the latter terms
        shall control with respect to your use of that portion of this Site or
        such specific product or service.
      </p>
      <p>
        {{ $store.state.brand.name }} or advertisers on this Site may make
        changes to any product or service offered on this Site or to the
        applicable prices, at any time, without notice.
      </p>
      <h5>
        Account Security
      </h5>
      <p>
        Certain Site features and services may require you to open an account.
        You are solely responsible for keeping your account information
        (including, without limitation, your password) confidential, and for any
        and all activity that occurs through your account as a result of your
        failing to keep this information confidential. You agree to notify
        {{ $store.state.brand.name }} immediately of any unauthorized use of
        your account, and of any other breach of security. You may be held
        liable for losses incurred by {{ $store.state.brand.name }} or any other
        user of this Site due to someone else using your account as a result of
        your failing to keep your account information secure and confidential.
        You may not use anyone else’s user ID, password or account on this Site
        at any time without the express permission and consent of the holder of
        that user ID, password or account. {{ $store.state.brand.name }}
        cannot and will not be liable for any loss or damage arising from your
        failure to comply with these obligations.
      </p>
      <h5>
        Privacy
      </h5>
      <p>
        The {{ $store.state.brand.name }} Privacy Statement posted on this Site
        applies to your use of this Site, and its terms are made a part of these
        Terms of Use by this reference. To view the
        {{ $store.state.brand.name }} Privacy Statement,
        <router-link to="/privacy">click here</router-link>.
      </p>
      <h5>
        Links to Other Sites
      </h5>
      <p>
        This Site may contain links to independent third-party websites (“Linked
        Sites”). {{ $store.state.brand.name }} provides these Linked Sites
        solely for your convenience, and does not control or endorse any of
        them. {{ $store.state.brand.name }}
        cannot be responsible for the content, security or privacy policies or
        practices of such Linked Sites.
      </p>
      <h5>
        Suspension or Termination of Your Use of this Site
      </h5>
      <p>
        {{ $store.state.brand.name }} may, in its sole discretion and without
        prior notice, suspend or terminate your access to this Site and/or block
        your future access to this Site, including as a result of (i) your
        violation of these Terms of Use or other agreements or guidelines
        associated with your use of this Site or any product or service offered
        on or through this Site, (ii) requests by law enforcement or other
        government agencies, (iii) a request by you (including self-initiated
        account deletions), (iv) discontinuance or material modification of this
        Site or any service offered on or through this Site, or (v) unexpected
        technical issues or problems. You acknowledge and agree that any
        violation by you of these Terms of Use will constitute an unlawful and
        unfair business practice, and will cause irreparable harm to
        {{ $store.state.brand.name }}, for which monetary damages would be
        inadequate, and you consent to {{ $store.state.brand.name }} obtaining
        any injunctive or equitable relief that
        {{ $store.state.brand.name }} deems necessary or appropriate in such
        circumstances. These remedies are in addition to any other remedies
        {{ $store.state.brand.name }} may have at law or in equity.
      </p>
      <p>
        {{ $store.state.brand.name }} also reserves the right to, at any time,
        without notice, modify, interrupt, suspend or terminate the operation of
        or access to this Site, or any portion of this Site, for any reason
        (including, without limitation, maintenance, error correction, etc.).
      </p>
      <h5>
        Disclosure of Your Information
      </h5>
      <p>
        {{ $store.state.brand.name }} may disclose any information we have about
        you (including, without limitation, your identity or any transmittal or
        communication by you with {{ $store.state.brand.name }} through this
        Site or any product or service offered on or through this Site) if we
        determine that such disclosure is necessary (i) to enforce these Terms
        of Use, (ii) in connection with any investigation or complaint regarding
        your use of this Site or any product or service offered on or through
        this Site, (iii) to protect the rights, property or personal safety of
        {{ $store.state.brand.name }}, its employees, users of or visitors to
        this Site, and the public, (iv) to comply with any applicable law,
        regulation, legal process or governmental request, or to respond to
        claims that any such data violates the rights of others, or (vi) to
        exchange information and cooperate with other companies or organizations
        for fraud protection purposes, as required or permitted by applicable
        law. In addition, {{ $store.state.brand.name }} may disclose your
        information to third parties if {{ $store.state.brand.name }} is
        involved in a merger, acquisition or sale of any or all of
        {{ $store.state.brand.name }}’s business and/or assets to a third party,
        provided that they agree to abide by the
        {{ $store.state.brand.name }} Privacy Statement posted on this Site.
      </p>
      <p>
        If {{ $store.state.brand.name }} takes any legal action against you due
        to your violation of these Terms of Use,
        {{ $store.state.brand.name }} will be entitled to recover from you, and
        you agree to pay, all reasonable attorneys’ fees and costs of such
        action, in addition to any other relief granted to
        {{ $store.state.brand.name }}. {{ $store.state.brand.name }}
        will not be liable to you or to any third party for termination of your
        access to this Site for any reason.
      </p>
      <h5>
        Disclaimers; Limitation of Liability
      </h5>
      <p>
        THIS SITE AND ITS CONTENT ARE DELIVERED ON AN “AS-IS” AND “AS-AVAILABLE”
        BASIS WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING ANY
        WARRANTIES OF ACCURACY, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS
        FOR A PARTICULAR PURPOSE. {{ $store.state.brand.name }} DOES NOT WARRANT
        THAT (A) THIS SITE OR ANY CONTENT, PRODUCT, SERVICE OR FEATURE OF THIS
        SITE WILL BE ERROR-FREE, UNINTERRUPTED OR SECURE, OR ANY DEFECTS WILL BE
        CORRECTED; (B) ANY FILES OR OTHER DATA YOU DOWNLOAD FROM THIS SITE WILL
        BE FREE OF VIRUSES OR CONTAMINATION OR DESTRUCTIVE FEATURES; OR (C) ANY
        INFORMATION YOU TRANSMIT TO THIS SITE WILL BE SECURE IN ITS TRANSMISSION
        AND/OR STORAGE. ALL INFORMATION PROVIDED ON THIS SITE IS SUBJECT TO
        CHANGE WITHOUT NOTICE. IT IS YOUR RESPONSIBILITY TO CONFIRM WITH
        {{ $store.state.brand.name }} THE ACCURACY AND COMPLETENESS OF ALL
        POSTED INFORMATION BEFORE MAKING ANY DECISION BASED ON SUCH INFORMATION.
        {{ $store.state.brand.name }} DISCLAIMS ANY AND ALL LIABILITY FOR THE
        ACTS, OMISSIONS AND CONDUCT OF ANY THIRD PARTIES IN CONNECTION WITH OR
        RELATED TO YOUR USE OF THIS SITE AND/OR ANY
        {{ $store.state.brand.name }} PRODUCTS AND/OR SERVICES. YOU ASSUME TOTAL
        RESPONSIBILITY FOR YOUR USE OF THIS SITE AND ANY LINKED SITES. YOUR SOLE
        REMEDY AGAINST {{ $store.state.brand.name }} FOR DISSATISFACTION WITH
        THIS SITE OR ANY CONTENT IS TO STOP USING THIS SITE OR ANY SUCH CONTENT.
        THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES.
      </p>
      <p>
        Except where prohibited by law, in no event will
        {{ $store.state.brand.name }} be liable to you for any direct, indirect,
        consequential, special, exemplary, incidental, or punitive whatsoever
        damages, including lost profits, even if
        {{ $store.state.brand.name }} has been advised of the possibility of
        such damages. If, notwithstanding the other provisions of these Terms of
        Use, {{ $store.state.brand.name }} is found to be liable to you for any
        damage or loss which arises out of or is in any way connected with your
        use of this Site or any Content, {{ $store.state.brand.name }}’s total
        liability shall in no event exceed US $100.00.
      </p>
      <p>
        The above disclaimers and limitation of liability apply to any damages,
        liability or injuries caused by any failure of performance, error,
        omission, interruption, deletion, defect, delay in operation or
        transmission, computer virus, communication line failure, theft or
        destruction of or unauthorized access to, alteration of, or use, whether
        for breach of contract, tort, negligence or any other cause of action,
        to the fullest extent permitted by the law of the applicable
        jurisdiction.
      </p>
      <h5>
        Indemnity
      </h5>
      <p>
        You agree to indemnify and hold {{ $store.state.brand.name }}, its
        existing and future officers, directors, shareholders, predecessors,
        successors in interest, employees, agents, parents, subsidiaries and
        other affiliates, harmless from any demands, loss, liability, claims or
        expenses (including reasonable attorneys’ fees), made against
        {{ $store.state.brand.name }} by any third party due to or arising out
        of or in connection with your use of this Site.
      </p>
      <h5>
        Dispute Resolution; Class Action Waiver
      </h5>
      <p>
        All matters relating to your access to or use of this Site, including
        all disputes, will be governed by the laws of the State of Illinois
        without regard to its conflicts of laws provisions. You agree to the
        personal jurisdiction by and venue in the state and federal courts in
        Cook County, Illinois, and waive any objection to such exclusive
        jurisdiction or venue. Resolution of all disputes shall be conducted and
        resolved on an individual basis only, and not on a class-wide, multiple
        plaintiff, multiple claimant, consolidated or similar basis. You waive
        any right to participate in any way in a class action lawsuit against
        {{ $store.state.brand.name }}, or to act in any arbitration, lawsuit,
        action or other legal proceeding against
        {{ $store.state.brand.name }} in the interest of the public or in any
        private attorney general capacity.
      </p>
      <p>
        Any claim under these Terms of Use must be brought within one (1) year
        after the cause of action arises, or such claim or cause of action is
        barred. Claims made under the separate terms and conditions of purchase
        for products and services are not subject to this limitation. The
        prevailing party will be entitled to reasonable costs and attorneys’
        fees.
      </p>
      <h5>
        International Issues
      </h5>
      <p>
        {{ $store.state.brand.name }} administers and operates this Site from
        its locations in the United States. Information on
        {{ $store.state.brand.name }}’s Site may contain references to products,
        programs and services that are not available in your country. Such
        reference does not imply that {{ $store.state.brand.name }} or any third
        party whose products, programs and services are featured on this Site
        intends to make or continue to make such products, programs, or services
        available in your country now or in the future. Any offer on this Site
        for any feature, product, or service is void where prohibited. If you
        choose to access this Site from outside the United States, you are
        responsible for complying with applicable local laws. This Site and the
        products and services offered on or through this Site are not intended
        to be offered to or used by residents in Europe.
      </p>
      <p>
        Certain text in this Site may be available in languages other than
        English. These translations are provided as a convenience to you, and
        {{ $store.state.brand.name }} makes no representations or commitments
        regarding the accuracy or completeness of the translation.
      </p>
      <h5>
        Miscellaneous
      </h5>
      <p>
        If a court or other tribunal of competent jurisdiction holds any of the
        provisions of these Terms of Use to be void or unenforceable, such
        provisions shall be limited or eliminated to the minimum extent
        necessary and replaced with a valid provision that best embodies the
        intent of these Terms of Use, so that these Terms of Use shall remain in
        full force and effect.
      </p>
      <p>
        These Terms of Use (together with any additional terms presented to you
        in connection with your gaining access to certain portions of this Site)
        constitute the entire agreement between you and
        {{ $store.state.brand.name }} with regard to your use of this Site, and
        any and all other written or oral agreements or understandings
        previously existing between you and {{ $store.state.brand.name }} with
        respect to such use are hereby superseded and cancelled.
      </p>
      <p>
        {{ $store.state.brand.name }}’s failure to insist on or enforce strict
        performance of these Terms of Use shall not be construed as
        {{ $store.state.brand.name }}’s waiver of any provision or any right it
        has to enforce these Terms of Use, nor shall any course of conduct
        between {{ $store.state.brand.name }} and you or any other party be
        deemed to modify any provision of these Terms of Use. These Terms of Use
        shall not be interpreted or construed to confer any rights or remedies
        on any third parties. {{ $store.state.brand.name }} customer service
        representatives or other personnel are not authorized to modify any
        provision of these terms, either verbally or in writing.
      </p>
      <h5>
        Feedback
      </h5>
      <p>
        {{ $store.state.brand.name }} welcomes any feedback you have regarding
        this Site. Please be aware that any feedback you provide shall be deemed
        non-confidential, and {{ $store.state.brand.name }} shall be free to use
        such information on an unrestricted basis. Please provide any feedback
        to the following address:
      </p>
      <br />
      <p>
        {{ $store.state.brand.name }}<br />
        c/o {{ $store.state.brand.name }}<br />
        2027 W. Division St. #185<br />
        Chicago, IL 60622<br /><br />
        These Terms and Services were last updated on January 4th, 2021.<br />
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "TermsOfUse",
  /*metaInfo: {
    titleTemplate: "%s | Terms of Service"
  },*/
  mounted() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
};
</script>

<style scoped lang="scss">
.tos {
  margin-top: $titlebar-height;

  .tos__content {
    max-width: 1000px;
    margin: 0 auto;
    padding: $space-xl $space-lg;

    @include screen-md {
      padding: $space-xl $space-md;
    }
  }

  h1,
  h2,
  h5,
  h4,
  h5,
  h6 {
    margin: 1em 0;
  }
}
</style>
